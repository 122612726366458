.hero {
  // General Styles
  display: flex;

  background-image: url("../../images/image-homepage-hero.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: flex-end;

  .line {
    border: 1px solid rgba(211, 211, 211, 0.308);
    width: 100%;
  }

  #name {
    color: "black";
    text-decoration-color: "underline";
  }

  // Small Screens
  @media only screen and (max-width: 600px) {
    background-image: none;
    padding: 2rem;
    flex-direction: column;
    align-items: center;

    .section-seperator {
      height: 1px;
      width: 80vw;
      margin-top: 4rem;
      margin-bottom: 3rem;
    }
  }

  // Medium Screens
  @media screen and (min-width: 600px) and (max-width: 960px) {
  }

  // Large Screens
  @media screen and (min-width: 960px) {
    margin: 2rem 0;
    height: 65vh;

    .section-seperator {
      width: 0;
    }
  }
}

.text-container {
  // General Styles

  background-color: #fafafa;

  h1 {
    font-weight: 700;
  }

  #about-me-button {
    background-color: #203a4c;
    border-radius: 0;
    border: 0;

    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.06rem;
    text-transform: uppercase;

    padding: 1rem 3rem;
  }

  // Small Screens
  @media only screen and (max-width: 600px) {
    text-align: center;
    width: 100%;

    #about-me-button {
      margin-top: 2rem;
    }
  }

  // Medium Screens
  @media screen and (min-width: 600px) and (max-width: 960px) {
  }

  // Large Screens
  @media screen and (min-width: 960px) {
    padding-top: 2.5rem;
    padding-right: 2.5rem;

    width: 40%;

    h1 {
      font-size: 3rem;
      padding-bottom: 2.5rem;
      padding-right: 1rem;
    }
  }
}
