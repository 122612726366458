.header-link {
  // General Styles
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.06rem;
  text-transform: uppercase;

  // Small Screens
  @media screen and (max-width: 600px) {
    padding-top: 2rem;
  }

  // Medium Screens
  @media screen and (min-width: 600px) and (max-width: 960px) {
  }

  // Large Screens
  @media screen and (min-width: 960px) {
    padding: 2rem 0;
  }
}

.navigation {
  // General Styles

  // Small Screens
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }

  // Medium Screens
  @media screen and (min-width: 600px) and (max-width: 960px) {
  }

  // Large Screens
  @media screen and (min-width: 960px) {
  }
}
