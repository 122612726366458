#portfolio-index {
  // General Styles

  .github-link {
    color: #6c757d;
    padding: 0;
    text-decoration: underline;
  }

  .github-link:hover {
    color: #9faebb;
  }

  // Small Screens
  @media screen and (max-width: 600px) {
    #planet-hub-image {
      margin-top: 2rem;
      margin-left: 1rem;
    }

    .desktop {
      display: none;
    }

    .image-link {
      padding: 0;
    }
  }

  // Medium Screens
  @media screen and (min-width: 600px) and (max-width: 960px) {
  }
  // Large Screens
  @media screen and (min-width: 960px) {
    #audiophile-image:hover,
    #planet-hub-image:hover {
      transform: scale(1.05);
      transition: transform 200ms ease-in-out;
    }

    #planet-hub-image {
      margin-left: 5rem;
    }

    .mobile {
      display: none;
    }

    .portfolio-item-description {
      line-height: 3;
    }
  }
}
