h1,
h2,
h3 {
  font-family: "Ibarra Real Nova", serif;
}

header,
main,
footer {
  font-family: "Public Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}
