.contact-me {
  // General Styles
  margin: 2.5rem 0;
  padding: 2.5rem 0;

  display: flex;
  align-items: center;

  a {
    font-size: 0.8rem;
    height: 10%;
    width: 20%;
    border-radius: 0;
    padding: 1rem 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 600;
    width: 40%;
  }

  .line {
    border: 1px solid rgba(211, 211, 211, 0.308);
    width: 100%;
  }

  .line-contact {
    width: 60%;

    padding: 0 2rem;
    margin: 0 2rem;
  }

  // Small Screens
  @media screen and (max-width: 600px) {
    flex-direction: column;
    text-align: center;

    a {
      width: 50%;
    }

    h2 {
      width: 100%;
      padding-bottom: 2rem;
    }

    .line-contact {
      display: none;
    }

    .section-seperator {
      width: 80vw;
      margin-bottom: 4rem;
    }
  }

  // Medium Screens
  @media screen and (min-width: 600px) and (max-width: 960px) {
  }

  // Large Screens
  @media screen and (min-width: 960px) {
    .section-seperator {
      width: 0;
    }
  }
}
